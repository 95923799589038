/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/formstone@1.4.18-1/src/js/core.min.js
 * - /npm/jquery.cookie@1.4.1/jquery.cookie.min.js
 * - /npm/nanoscroller@0.8.7/bin/javascripts/jquery.nanoscroller.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
